export const arbitrumTestnet = {
    name: "ARBITRUM-TESTNET",
    prodNet: "ARBITRUM",
    iconUrl: "/images/arbitrum_icon.svg",
    CHAIN_ID: 421611,
    customRpc: {"chainId":"0x66eeb","chainName":"Arbitrum Testnet","nativeCurrency":{"name":"ETH","symbol":"ETH","decimals":18},"rpcUrls":["https://rinkeby.arbitrum.io/rpc"],"blockExplorerUrls":["https://rinkeby-explorer.arbitrum.io"]},
    apiUrl: null,
    subgraphUrl: "https://api.thegraph.com/subgraphs/name/auctusproject/auctus-options-arbitrum-rinkeby",
    rpcWssUrl: null,
    rpcApiUrl: "https://rinkeby.arbitrum.io/rpc",
    zrxApiUrl: null,
    zrxWSSUrl: null,
    zrxRequestPerSecond: 3,
    explorerUrl: "https://rinkeby-explorer.arbitrum.io/address/",
    explorerTxUrl: "https://rinkeby-explorer.arbitrum.io/tx/",
    swapUrl: null,
    gasApiUrl: null,
    gasPriceType: null,
    defaultGasPrice: null,
    hasAave: true,
    acoFactoryAddress: "0x952703308fe5be62e857ab453d1664589662d834",
    acoPoolFactoryAddress: "0x1fb07f95f2bed7575832ed85f2fb150e0b91af67",
    acoFlashExerciseAddress: "0xa6c17ef657e24aad9ceab20cdc017f560dbcb2a8",
    acoWriterAddress: null,
    zrxExchangeAddress: null,
    multicallAddress: null,
    allAcoOtcAddresses: ["0x02dbc86c1c410549c546ad5e9ab2a27ce72a87d2"],
    acoBuyerAddress: "0x9a90bb2c0de276c895cb024a7d7399ca01f29b2d",
    acoDistributorAddress: null,
    acoRewardAddress: null,
    airdropClaimStart: null,
    acoAssetConverterHelperAddress: "0xd4c96ad96ba790856aeb42e31d63b314ff0a1d15",
    auctusAddress: null,
    usdAddress: "0x5cdf2373a02362fef0e035edfc64292cb7ea33ea",
    wrapperAddress: "0x2f10d5a94b926b546f7e33267bcd6318cdf5ed6d",
    btcAddress: "0xf57c505f9882052ddeb24bc3ab7f1b017690277a",
    ethAddress: "0x0000000000000000000000000000000000000000",
    baseAddress: "0x0000000000000000000000000000000000000000",
    usdSymbol: "T06D",
    btcSymbol: "T08D",
    ethSymbol: "ETH",
    baseSymbol: "ETH",
    deprecatedPoolImplementation: [],
    acoVaults: null,
    acoVaultsV2: null,
    defaultPoolAdmin: "0xf791a034c20fc9cadc1dec0d4a5f9449a88f64a4",
    defaultAcoCreators: ["0xf791a034c20fc9cadc1dec0d4a5f9449a88f64a4"],
    optionsToIgnore: [],
    acoRewardsPools: null,
    acoAirdropAmounts: null,
    coingeckoPlataform: "ethereum",
    coingeckoBaseAsset: "ethereum",
    menuConfig: { hasAdvanced: false, hasVaults: false, hasOtc: true, hasFarm: false, hasCreateOption: true, hasPoolHistory: true }
}