export const bscTestnet = {
    name: "BSC-TESTNET",
    prodNet: "BSC",
    iconUrl: "/images/bsc_icon.svg",
    CHAIN_ID: 97,
    customRpc: {"chainId":"0x61","chainName":"Binance Smart Chain Testnet","nativeCurrency":{"name":"BNB","symbol":"BNB","decimals":18},"rpcUrls":["https://data-seed-prebsc-1-s1.binance.org:8545/"],"blockExplorerUrls":["https://testnet.bscscan.com"]},
    apiUrl: "https://lliatip6ta.execute-api.us-east-1.amazonaws.com/dev/",
    subgraphUrl: null,
    rpcWssUrl: null,
    rpcApiUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    zrxApiUrl: null,
    zrxWSSUrl: null,
    zrxRequestPerSecond: 3,
    explorerUrl: "https://testnet.bscscan.com/address/",
    explorerTxUrl: "https://testnet.bscscan.com/tx/",
    swapUrl: "https://exchange.pancakeswap.finance/#/swap?outputCurrency=",
    gasApiUrl: null,
    gasPriceType: null,
    defaultGasPrice: null,
    hasAave: false,
    acoFactoryAddress: "0xed5f507fec2854d85bebfb62b9085532bba3a912",
    acoPoolFactoryAddress: "0x6045b669ce9b31b1b851ca8e0b578efb5645d3df",
    acoFlashExerciseAddress: "0xce4b9b40a88cf1cbd8958b153cbe25325e4ce4f2",
    acoWriterAddress: null,
    zrxExchangeAddress: null,
    multicallAddress: null,
    allAcoOtcAddresses: ["0xf3fa7b01450c03655330198b3145f75bfdcf0aca"],
    acoBuyerAddress: "0x0c819d1ddaca1c98cd12a19c13f5716de6e5bdb9",
    acoDistributorAddress: null,
    acoRewardAddress: null,
    airdropClaimStart: null,
    acoAssetConverterHelperAddress: "0x2121b20c077b7de1cb9fd3ec184408f246d0f12a",
    auctusAddress: "0xc98e1399f6f22aa12a4c08b05caf50e0c10abf2f",
    usdAddress: "0x4e69dac3a9d4b91c48d17f805bf9237484f9e040",
    wrapperAddress: "0xc4f52d50de4069742417193aabe29218988a4395",
    btcAddress: "0x952703308fe5be62e857ab453d1664589662d834",
    ethAddress: "0x5627949e60e8dc1290a8617019cbb6b5dd36edb3",
    baseAddress: "0x0000000000000000000000000000000000000000",
    usdSymbol: "T18D",
    btcSymbol: "T18D3",
    ethSymbol: "T18D2",
    baseSymbol: "BNB",
    deprecatedPoolImplementation: ["0xf5b411b9bf2a28877ae560665885617c9bfe54be"],
    acoVaults: null,
    acoVaultsV2: null,
    defaultPoolAdmin: "0xf791a034c20fc9cadc1dec0d4a5f9449a88f64a4",
    defaultAcoCreators: ["0xf791a034c20fc9cadc1dec0d4a5f9449a88f64a4"],
    optionsToIgnore: [],
    acoRewardsPools: null,
    acoAirdropAmounts: null,
    coingeckoPlataform: "binance-smart-chain",
    coingeckoBaseAsset: "binancecoin",
    menuConfig: { hasAdvanced: false, hasVaults: false, hasOtc: true, hasFarm: false, hasCreateOption: false, hasPoolHistory: false }
}